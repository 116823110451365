<template>
  <div class="c-mini-basket__container">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="me-2">
          <i class="nt-ci shopping--cart--arrow-down"></i>
        </div>
        <div>
          <div class="c-mini-basket__title">
            {{ !showWeekOverview ? titleShoppingCart : titleWeekOverview }}
          </div>
          <div class="c-mini-basket__catalog-name">
            {{ !showWeekOverview ? catalogName : labelWeek }}
            {{ !showWeekOverview ? "" : weekNumber }}
          </div>
        </div>
      </div>

      <div v-show="weekOverviewItemList.items.length > 0">
        <button class="c-mini-basket__toggle" type="button" @click="toggleWeekOverview">
          <i v-show="showWeekOverview === false" class="nt-ci chevron--up"></i>
          <i v-show="showWeekOverview === true" class="nt-ci chevron--down"></i>
        </button>
      </div>
    </div>

    <div v-show="showWeekOverview === true" class="c-mini-basket__week-overview">
      <div class="c-mini-basket__week-overview-item-list">
        <div
          v-for="weekOverviewItem in weekOverviewItemList.items"
          :key="weekOverviewItem.catalogName"
          class="c-mini-basket__week-overview-item card mb-2"
        >
          <div class="card-body">
            <a :href="weekOverviewItem.basketUrl" class="stretched-link"></a>
            <div class="c-mini-basket__week-overview-item-title">
              {{ weekOverviewItem.catalogName }}
            </div>
            <div class="c-mini-basket__week-overview-item-trolley-count">
              {{ weekOverviewItem.trolleysString }}
              {{ labelTrolley }}
            </div>
          </div>
        </div>
      </div>

      <hr class="my-2" />

      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="c-mini-basket__week-overview-total-price-label">
          {{ labelWeekOverviewTotal }}
        </div>
        <div class="c-mini-basket__week-overview-total-price">
          {{ weekOverviewTotalPriceString }}
        </div>
      </div>

      <div class="c-mini-basket__week-overview-trolley card mb-2">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="c-mini-basket__week-overview-trolley-count">
                {{ weekOverviewTrolleysString }} {{ labelTrolley }}
              </div>
            </div>
            <div class="d-flex">
              <div v-show="showFullTrolleyIcon" class="nt-trolley-icon nt-trolley-icon--full ms-1">
                <img alt="" src="../assets/trolley-icon.svg" />
              </div>
              <div
                v-show="showPartialTrolleyIcon"
                :class="weekOverviewTrolleysPartialIconClass"
                class="nt-trolley-icon ms-1"
              >
                <!-- added as SVG to make it accessible by CSS -->
                <svg fill="none" height="29" viewBox="0 0 28 29" width="28" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2501_27559)">
                    <path
                      d="M4 26.4017H7.2V26.4017C7.2 27.2854 6.48366 28.0017 5.6 28.0017V28.0017C4.71634 28.0017 4 27.2854 4 26.4017V26.4017Z"
                      fill="#82C63F"
                    />
                    <path
                      d="M20 26.4017H23.2V26.4017C23.2 27.2854 22.4837 28.0017 21.6 28.0017V28.0017C20.7163 28.0017 20 27.2854 20 26.4017V26.4017Z"
                      fill="#82C63F"
                    />
                    <rect
                      class="nt-trolley-icon__layer--partial-100"
                      fill="#82C63F"
                      height="4"
                      width="16"
                      x="5.6001"
                      y="3.202"
                    />
                    <rect
                      class="nt-trolley-icon__layer--partial-75"
                      fill="#82C63F"
                      height="4.8"
                      width="16"
                      x="5.6001"
                      y="8.0018"
                    />
                    <rect
                      class="nt-trolley-icon__layer--partial-50"
                      fill="#82C63F"
                      height="4.8"
                      width="16"
                      x="5.6001"
                      y="13.6019"
                    />
                    <rect
                      class="nt-trolley-icon__layer--partial-25"
                      fill="#82C63F"
                      height="4.8"
                      width="16"
                      x="5.6001"
                      y="19.202"
                    />
                    <path
                      d="M23.2 25.6018V0.8018C23.2 0.359973 22.8418 0.00180054 22.4 0.00180054C21.9582 0.00180054 21.6 0.359972 21.6 0.8018V24.0018H5.6V0.8018C5.6 0.359973 5.24183 0.00180054 4.8 0.00180054C4.35817 0.00180054 4 0.359973 4 0.801801V25.6018H23.2Z"
                      fill="#82C63F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2501_27559">
                      <rect fill="white" height="28" transform="translate(0 0.00180054)" width="28" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="showWeekOverview === false">
      <div class="d-flex justify-content-between d-md-block">
        <div class="d-flex justify-content-start justify-content-md-between align-items-center mb-2">
          <div>
            <div class="c-mini-basket__trolley-count text-nowrap">{{ trolleysString }} {{ labelTrolley }}</div>
            <div class="c-mini-basket__total-price text-nowrap">
              {{ totalPriceString }}
            </div>
          </div>
          <div class="d-flex">
            <div v-show="showFullTrolleyIcon" class="nt-trolley-icon nt-trolley-icon--full ms-1">
              <img alt="" src="../assets/trolley-icon.svg" />
            </div>
            <div
              v-show="showPartialTrolleyIcon"
              :class="trolleysPartialIconClass"
              class="nt-trolley-icon ms-1 me-2 me-md-0"
            >
              <!-- added as SVG to make it accessible by CSS -->
              <svg fill="none" height="29" viewBox="0 0 28 29" width="28" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2501_27559)">
                  <path
                    d="M4 26.4017H7.2V26.4017C7.2 27.2854 6.48366 28.0017 5.6 28.0017V28.0017C4.71634 28.0017 4 27.2854 4 26.4017V26.4017Z"
                    fill="#82C63F"
                  />
                  <path
                    d="M20 26.4017H23.2V26.4017C23.2 27.2854 22.4837 28.0017 21.6 28.0017V28.0017C20.7163 28.0017 20 27.2854 20 26.4017V26.4017Z"
                    fill="#82C63F"
                  />
                  <rect
                    class="nt-trolley-icon__layer--partial-100"
                    fill="#82C63F"
                    height="4"
                    width="16"
                    x="5.6001"
                    y="3.202"
                  />
                  <rect
                    class="nt-trolley-icon__layer--partial-75"
                    fill="#82C63F"
                    height="4.8"
                    width="16"
                    x="5.6001"
                    y="8.0018"
                  />
                  <rect
                    class="nt-trolley-icon__layer--partial-50"
                    fill="#82C63F"
                    height="4.8"
                    width="16"
                    x="5.6001"
                    y="13.6019"
                  />
                  <rect
                    class="nt-trolley-icon__layer--partial-25"
                    fill="#82C63F"
                    height="4.8"
                    width="16"
                    x="5.6001"
                    y="19.202"
                  />
                  <path
                    d="M23.2 25.6018V0.8018C23.2 0.359973 22.8418 0.00180054 22.4 0.00180054C21.9582 0.00180054 21.6 0.359972 21.6 0.8018V24.0018H5.6V0.8018C5.6 0.359973 5.24183 0.00180054 4.8 0.00180054C4.35817 0.00180054 4 0.359973 4 0.801801V25.6018H23.2Z"
                    fill="#82C63F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2501_27559">
                    <rect fill="white" height="28" transform="translate(0 0.00180054)" width="28" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <a :href="basketUrl" class="btn btn-secondary btn-lg w-100 text-nowrap">
            <span class="nt-hide-under-320px">{{ actionGoToBasket }}</span>
            <i class="nt-ci arrow-right"></i>
          </a>
        </div>
      </div>
    </div>

    <a v-show="showWeekOverview === true" :href="weekOverviewUrl" class="btn btn-secondary btn-lg w-100 text-nowrap">
      {{ actionGoToWeekOverview }} <i class="nt-ci arrow-right"></i>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import Multilang from "../script/utils/multilang";
import CarbonIcons from "../script/utils/carbon-icons";
import MiniBasketData from "../composables/mini-basket-data";
import { MiniBasketResponse } from "../script/models/basket";
import eventBus from "../script/utils/event-bus";
import "../script/utils/number-extensions";
import CatalogIdProvider from "../script/utils/catalog-id-provider";

function getData() {
  Multilang.load();

  let catalogName = MiniBasketData.catalogName;
  let totalPriceString = MiniBasketData.totalPriceString;
  let trolleys = MiniBasketData.trolleys;
  let trolleysString = MiniBasketData.trolleysString;
  let trolleysPartialIconClass = MiniBasketData.trolleysPartialIconClass;
  let basketUrl = MiniBasketData.basketUrl;

  let weekNumber = MiniBasketData.weekNumber;
  let weekOverviewItemList = MiniBasketData.weekOverviewItemList;
  let weekOverviewTotalPriceString = MiniBasketData.weekOverviewTotalPriceString;
  let weekOverviewTrolleys = MiniBasketData.weekOverviewTrolleys;
  let weekOverviewTrolleysString = MiniBasketData.weekOverviewTrolleysString;
  let weekOverviewTrolleysPartialIconClass = MiniBasketData.weekOverviewTrolleysPartialIconClass;
  let weekOverviewUrl = MiniBasketData.weekOverviewUrl;

  const catalogIdSearch = $('[name="CurrentCatalogId"]').val() as string | null;
  const catalogIdDocs = $("#CurrentCatalogIdForDocsView").val() as string | null;

  const catalogId = CatalogIdProvider.getCurrentCatalogId();

  axios
    .get<MiniBasketResponse>(`/cart/mini?catalogId=${catalogId}`)
    .then((res) => res.data)
    .then((data) => {
      MiniBasketData.set(data);

      if (MiniBasketData.totalPrice.value > 0) {
        eventBus.emit("basket-filled");
      }

      // only show after initial load
      $(".js-vue--mini-basket").removeClass("d-none");

      // carbon icons
      const element = document.querySelector(".js-vue--mini-basket") ?? undefined;
      CarbonIcons.init(element);
    })
    .catch((err) => console.log(err));

  return {
    totalPriceString,
    catalogName,
    trolleys,
    trolleysString,
    trolleysPartialIconClass,
    basketUrl,
    weekNumber,
    weekOverviewItemList,
    weekOverviewTotalPriceString,
    weekOverviewTrolleys,
    weekOverviewTrolleysString,
    weekOverviewTrolleysPartialIconClass,
    weekOverviewUrl,
  };
}

export default defineComponent({
  name: "MiniBasket",
  data() {
    return {
      showWeekOverview: false,
      titleShoppingCart: Multilang.getTranslation("mini-basket.title.shopping-cart", "Shopping cart"),
      titleWeekOverview: Multilang.getTranslation("mini-basket.title.week-overview", "Week overview"),
      labelTrolley: Multilang.getTranslation("mini-basket.label.trolley", "Trolley"),
      labelWeek: Multilang.getTranslation("mini-basket.label.week", "Week"),
      labelWeekOverviewTotal: Multilang.getTranslation("mini-basket.label.week-overview-total", "Week total"),
      actionGoToBasket: Multilang.getTranslation("mini-basket.action.go-to-basket", "Go to basket"),
      actionGoToWeekOverview: Multilang.getTranslation("mini-basket.action.go-to-week-overview", "Go to week overview"),
    };
  },
  setup: (props, context) => {
    eventBus.on("basket-updated", () => {
      getData();
    });

    return getData();
  },
  methods: {
    toggleWeekOverview() {
      this.showWeekOverview = !this.showWeekOverview;
    },
  },
  computed: {
    showFullTrolleyIcon(): boolean {
      return !this.showWeekOverview ? this.trolleys >= 0.9 : this.weekOverviewTrolleys >= 0.9;
    },
    showPartialTrolleyIcon(): boolean {
      return !this.showWeekOverview
        ? this.trolleys.mod(1, 1) > 0.1 && this.trolleys.mod(1, 1) < 0.9
        : this.weekOverviewTrolleys.mod(1, 1) > 0.1 && this.weekOverviewTrolleys.mod(1, 1) < 0.9;
    },
  },
});
</script>

<style lang="scss" scoped></style>
