import { reactive, ref } from "vue";
import { MiniBasketResponse } from "../script/models/basket";

type MiniBasketWeekOverviewItem = {
  catalogName: string;
  trolleys: number;
  trolleysString: string;
  basketUrl: string;
};

export default class MiniBasketData {
  static catalogName = ref("");
  static totalPrice = ref(0);
  static totalPriceString = ref("");
  static trolleys = ref(0);
  static trolleysString = ref("");
  static trolleysPartialIconClass = ref("");
  static basketUrl = ref("");

  static weekNumber = ref(0);
  static weekOverviewItemList = reactive({
    items: [] as MiniBasketWeekOverviewItem[],
  });
  static weekOverviewTotalPrice = ref(0);
  static weekOverviewTotalPriceString = ref("");
  static weekOverviewTrolleys = ref(0);
  static weekOverviewTrolleysString = ref("");
  static weekOverviewTrolleysPartialIconClass = ref("");
  static weekOverviewUrl = ref("");

  static set(data: MiniBasketResponse) {
    this.catalogName.value = data.catalogName;
    this.totalPrice.value = data.totalPrice;
    this.totalPriceString.value = data.totalPriceString;
    this.trolleys.value = data.trolleys;
    this.trolleysString.value = data.trolleysString;
    this.trolleysPartialIconClass.value = data.trolleysPartialIconClass;
    this.basketUrl.value = data.basketUrl;

    // clear week overview item list
    this.weekOverviewItemList.items.splice(0, this.weekOverviewItemList.items.length);

    // add week overview item list
    for (let item of data.weekOverviewItemList) {
      this.weekOverviewItemList.items.push({
        catalogName: item.catalogName,
        trolleys: item.trolleys,
        trolleysString: item.trolleysString,
        basketUrl: item.basketUrl,
      });
    }

    this.weekNumber.value = data.weekNumber;
    this.weekOverviewTotalPrice.value = data.weekOverviewTotalPrice;
    this.weekOverviewTotalPriceString.value = data.weekOverviewTotalPriceString;
    this.weekOverviewTrolleys.value = data.weekOverviewTrolleys;
    this.weekOverviewTrolleysString.value = data.weekOverviewTrolleysString;
    this.weekOverviewTrolleysPartialIconClass.value = data.weekOverviewTrolleysPartialIconClass;
    this.weekOverviewUrl.value = data.weekOverviewUrl;
  }
}
