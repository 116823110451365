interface Number {
  mod(divisor: number, precision: number): number;
}

Number.prototype.mod = function (divisor: number, precision: number = 4) {
  const value: number = this as number;
  const result: string = (value / divisor).toString();

  const dot: number = result.indexOf(".");
  if (dot == -1) {
    return 0; // it was a perfect division (9 / 3 for example)
  }

  const remainder: string = "0." + result.substring(dot + precision); // get the part after the precision
  return parseFloat(remainder);
};
